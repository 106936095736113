import React, { useRef, useState } from "react";
import { VisibilityOff, Visibility, ArrowBackIos } from "@mui/icons-material";
import { useCreateUser } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";

export default function CreateUser() {
  const [showPassword, setShowPassword] = useState(false);

  const nameInputElement = useRef();
  const idInputElement = useRef();
  const emailInputElement = useRef();
  const roleInputElement = useRef();
  const hourlyRateInputElement = useRef();
  const overtimeRateInputElement = useRef();
  const passwordInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;

  const {
    mutate: createUserMutate,
    isLoading: isCreateUserLoading,
    isError: isCreateUserError,
    error: createUserError,
  } = useCreateUser();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      token: token,
      name: nameInputElement.current?.value,
      employeeCode: idInputElement.current?.value,
      email: emailInputElement.current?.value,
      role: roleInputElement.current?.value,
      hourlyRate: hourlyRateInputElement.current?.value,
      overtimeRate: overtimeRateInputElement.current?.value,
      password: passwordInputElement.current?.value,
    };

    createUserMutate(data);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <div className="pt-10 flex flex-col items-center"></div>

          <form onSubmit={handleSubmit} className="pb-8">
            <div className="space-y-3 md:space-y-4">
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Name
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="text"
                    className="outline-none w-full text-base font-medium text-black "
                    name="name"
                    placeholder="Full Name"
                    ref={nameInputElement}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Employee ID
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="text"
                    className="outline-none w-full text-base font-medium text-black "
                    name="empID"
                    placeholder="Employee ID code"
                    ref={idInputElement}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Email Address
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="email"
                    className="outline-none w-full text-base font-medium text-black "
                    name="email"
                    placeholder="user@gmail.com"
                    ref={emailInputElement}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Password
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="outline-none w-full text-base font-medium text-black"
                    name="password"
                    placeholder="password"
                    ref={passwordInputElement}
                  />
                  <div
                    onClick={handleTogglePassword}
                    className="hover:cursor-pointer"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Type
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <select
                    className="w-full outline-none"
                    name="role"
                    ref={roleInputElement}
                  >
                    <option value="">Select</option>
                    <option value="foreman">Foreman</option>
                    <option value="worker">Worker</option>
                    <option value="sales">Sales</option>
                  </select>
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Hourly Rate
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="number"
                    className="outline-none w-full text-base font-medium text-black "
                    name="hourlyRate"
                    placeholder="00"
                    ref={hourlyRateInputElement}
                    step=".01"
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label
                  className="text-base md:text-lg text-gray-500"
                  htmlFor=""
                >
                  Overtime Rate
                </label>
                <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="number"
                    className="outline-none w-full text-base font-medium text-black"
                    name="overtimeRate"
                    placeholder="00"
                    ref={overtimeRateInputElement}
                    step=".01"
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div className="pt-4">
                <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                  {isCreateUserLoading ? "is Creating" : "Create"}
                </button>
              </div>
              {isCreateUserError && (
                <div className="text-sm font-medium text-red-600 pt-2">
                  <p>{createUserError.response.data.error}</p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
