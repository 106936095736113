import React, { useRef, useState, useEffect } from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { useGetJobType, useEditJobType } from "../apiCalls/userApiCalls";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function EditJobType() {
  const nameInputElement = useRef();
  const typeInputElement = useRef();
  const rateInputElement = useRef();
  const retailtRateInputElement = useRef();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { id } = useParams();
  const {
    data: jobType,
    isLoading: isJobTypeLoading,
    isError: isJobTypeError,
    error: jobTypeError,
  } = useGetJobType(id, token);

  const {
    mutate: editUserMutate,
    isLoading: isEditUserLoading,
    isError: isEditUserError,
    error: editUserError,
  } = useEditJobType();

  const [formData, setFormData] = useState({
    title: "",
    type: "",
    rate: "",
    retailRate: "",
  });

  if (jobType) {
    console.log(jobType.data.data);
  }

  useEffect(() => {
    if (jobType) {
      setFormData({
        title: jobType.data.data.title,
        type: jobType.data.data.type,
        rate: jobType.data.data.rate,
        retailRate: jobType.data.data.retailRate,
      });
    }
  }, [jobType]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let rRate =
      typeInputElement.current?.value === "Percentage"
        ? 1
        : retailtRateInputElement.current?.value;
    const data = {
      id,
      token: token,
      title: nameInputElement.current?.value,
      type: typeInputElement.current?.value,
      rate: rateInputElement.current?.value,
      retailRate: rRate,
    };

    editUserMutate(data);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <div className="pt-10 flex flex-col items-center"></div>

          {isJobTypeLoading ? (
            <div>Loading...</div>
          ) : isJobTypeError ? (
            <div>Error: {jobTypeError.message}</div>
          ) : (
            <form onSubmit={handleSubmit} className="pb-8">
              <div className="space-y-3 md:space-y-4">
                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Title
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black "
                      name="name"
                      placeholder="Full Project, Fencing, Flooring, etc."
                      ref={nameInputElement}
                      defaultValue={formData.title}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Type
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <select
                      className="w-full outline-none"
                      name="type"
                      ref={typeInputElement}
                      defaultValue={formData.type}
                    >
                      <option value="">Select</option>
                      <option value="Percentage">Percentage</option>
                      <option value="Square Feet">Square Feet</option>
                      <option value="Linear Feet">Linear Feet</option>
                      <option value="Item">Item</option>
                    </select>
                  </div>
                </div>
                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Rate
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="number"
                      className="outline-none w-full text-base font-medium text-black "
                      name="rate"
                      placeholder=""
                      ref={rateInputElement}
                      step=".01"
                      defaultValue={formData.rate}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label
                    className="text-base md:text-lg text-gray-500"
                    htmlFor=""
                  >
                    Retail Rate
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="number"
                      className="outline-none w-full text-base font-medium text-black "
                      name="rate"
                      placeholder=""
                      ref={retailtRateInputElement}
                      step=".01"
                      defaultValue={formData.retailRate}
                      onWheel={(e) => e.target.blur()}
                    />
                  </div>
                </div>

                <div className="pt-4">
                  <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                    {isEditUserLoading ? "Updating..." : "Update"}
                  </button>
                </div>
                {isEditUserError && (
                  <div className="text-sm font-medium text-red-600 pt-2">
                    <p>{editUserError.response.data.error}</p>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
