function normalDate(d) {
  var date = new Date(d);
  return (
    date.getDate() +
    " " +
    date.toLocaleString("default", { month: "long" }) +
    " " +
    date.getFullYear()
  );
}

export function formatted(num) {
  return Number(num).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export default normalDate;
