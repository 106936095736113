import React, { useRef, useState } from "react";
import {
  MailOutlined,
  LockOutlined,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useLogin } from "../apiCalls/userApiCalls";

export default function Login() {
  const emailInputElement = useRef();
  const passwordInputElement = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const {
    mutate: userMutate,
    isLoading: isUserLoading,
    isError: isUserError,
    error: userError,
  } = useLogin();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      email: emailInputElement.current?.value,
      password: passwordInputElement.current?.value,
    };
    userMutate(data);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-6 w-full lg:max-w-xl">
          <div className="flex justify-center ">
            <Link to="/">
              <img src="images/logo.png" alt="" className="w-44" />
            </Link>
          </div>
          <h1 className="text-center text-xl md:text-2xl   font-bold text-transparent bg-[#59c3c2] bg-clip-text">
            Login
          </h1>
          <form className="pt-5 pb-8" onSubmit={handleSubmit}>
            <div className="space-y-4">
              <div className="space-y-1">
                <label className="md:text-lg" htmlFor="">
                  Your Email
                </label>
                <div className="bg-slate-100 rounded-xl py-4 px-5 flex items-center gap-2">
                  <MailOutlined />
                  <input
                    type="email"
                    placeholder="Email"
                    className="outline-none w-full text-sm md:text-base font-medium bg-slate-100 text-black placeholder-black "
                    name="email"
                    ref={emailInputElement}
                  />
                </div>
              </div>
              <div className="space-y-1">
                <label className="md:text-lg" htmlFor="">
                  Password
                </label>
                <div className="bg-slate-100 rounded-xl py-4 px-5 flex items-center gap-2">
                  <LockOutlined />
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="outline-none text-sm md:text-base font-medium w-full bg-slate-100 text-black placeholder-black"
                    name="password"
                    ref={passwordInputElement}
                  />
                  <div
                    onClick={handleTogglePassword}
                    className="hover:cursor-pointer"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </div>
                </div>
              </div>
              <div className="text-sm font-medium text-right text-[#FF5A7A]">
                <Link to="/forgetPassword">Forgot Passsword?</Link>
              </div>
            </div>
            <div className="pt-8">
              <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                {isUserLoading ? "...Logging In" : "Login"}
              </button>
            </div>

            {isUserError && (
              <div className="text-sm font-medium text-red-600 pt-2">
                <p>{userError.response.data.error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
