import React, { useRef, useState } from "react";
import {
  MailOutlined,
  LockOutlined,
  VisibilityOff,
  Visibility,
  ArrowBackIos,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useChangePassword } from "../apiCalls/userApiCalls";

export default function ChangePassword() {
  const oldPasswordInputElement = useRef();
  const newPasswordInputElement = useRef();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;

  const {
    mutate: changePasswordMutate,
    isLoading: isChangePasswordLoading,
    isError: isChangePasswordError,
    error: changePasswordError,
  } = useChangePassword();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      token: token,
      oldPassword: oldPasswordInputElement.current?.value,
      newPassword: newPasswordInputElement.current?.value,
    };

    changePasswordMutate(data);
  };

  const handleToggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:max-w-xl">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-center py-8 text-xl md:text-2xl font-bold text-transparent bg-[#59c3c2] bg-clip-text">
            Change Password
          </h1>
          <form onSubmit={handleSubmit} className="">
            <div className="space-y-4">
              <div className="space-y-1">
                <label htmlFor="" className="font-medium">
                  Old Password
                </label>
                <div className="bg-slate-100 rounded-xl py-4 px-5 flex gap-2">
                  <LockOutlined />
                  <input
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Password"
                    className="outline-none text-sm font-medium w-full bg-slate-100 text-black placeholder-black"
                    name="oldPassword"
                    ref={oldPasswordInputElement}
                  />

                  <div
                    onClick={handleToggleOldPassword}
                    className="hover:cursor-pointer"
                  >
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </div>
                </div>
              </div>
              <div className="space-y-1">
                <label htmlFor="" className="font-medium">
                  New Password
                </label>
                <div className="bg-slate-100 rounded-xl py-4 px-5 flex gap-2">
                  <LockOutlined />
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Password"
                    className="outline-none text-sm font-medium w-full bg-slate-100 text-black placeholder-black"
                    name="newPassword"
                    ref={newPasswordInputElement}
                  />
                  <div
                    onClick={handleToggleNewPassword}
                    className="hover:cursor-pointer"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-10">
              <button className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2] ">
                {isChangePasswordLoading ? "...Is Saving" : "Save"}
              </button>
            </div>
            {isChangePasswordError && (
              <div className="text-sm font-medium text-red-600 pt-2">
                <p>{changePasswordError.response.data.error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
