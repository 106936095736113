import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

import {
  KeyboardArrowRight,
  ExpandMore,
  ArrowBackIos,
} from "@mui/icons-material";
import moment from "moment";
import { formatted } from "../utils";
import { useGetJobDetails } from "../apiCalls/userApiCalls";

export default function Job() {
  const [workerHoursDetailsVisibility, setWorkerHoursDetailsVisibility] =
    useState({});

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { jobId } = useParams();

  const { isLoading: isJobDetailsLoading, data: jobDetails } = useGetJobDetails(
    jobId,
    token
  );

  function handleWorkerHoursDetails(workerId) {
    setWorkerHoursDetailsVisibility((prevState) => ({
      ...prevState,
      [workerId]: !prevState[workerId],
    }));
  }

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      <div className="flex w-full items-center justify-center">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-2xl font-medium pb-4">Job Details</h1>
          {isJobDetailsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="flex flex-col sm:flex-row sm:items-center justify-between px-6 border border-gray-100 btn-shadow py-3 rounded-xl">
                <div className="">
                  <span className="text-xl">
                    <b>{jobDetails.data.data.title}</b>
                  </span>{" "}
                  <br />
                  <span className="">
                    Started on:{" "}
                    {moment(jobDetails.data.data.addedOn).format(
                      "MMM DD, YYYY"
                    )}
                  </span>
                  <br />
                  {jobDetails.data.data.typeTitle && (
                    <>
                      <span className="">
                        Type: {jobDetails.data.data.typeTitle}
                      </span>
                      <br />
                    </>
                  )}
                  <span className="">
                    {jobDetails.data.data.jobType &&
                    jobDetails.data.data.jobType !== "Percentage"
                      ? `Area/Items: ${jobDetails.data.data.amount} ${jobDetails.data.data.jobType}`
                      : `Amount: ${formatted(jobDetails.data.data.amount)}`}
                  </span>
                  <br />
                  <span className="">
                    Sales Person: {jobDetails.data.data.salesId?.name}
                  </span>
                </div>
                <div className="sm:text-right">
                  <span className="">
                    Initial Budget:{" "}
                    {formatted(
                      (jobDetails.data.data.commissionRate *
                        jobDetails.data.data.amount) /
                        100
                    )}
                  </span>
                  <br />

                  {jobDetails.data.data.totalAdditionalBudget && (
                    <>
                      <span className="">
                        Additional Budget:{" "}
                        {formatted(
                          jobDetails.data.data.totalAdditionalBudget | 0
                        )}
                      </span>
                      <br />
                    </>
                  )}

                  <span className="font-semibold">
                    Total Budget:{" "}
                    {formatted(
                      Number(
                        (jobDetails.data.data.commissionRate *
                          jobDetails.data.data.amount) /
                          100
                      ) + Number(jobDetails.data.data.totalAdditionalBudget | 0)
                    )}
                  </span>

                  <br />

                  <span className=" font-medium text-red-500">
                    Total Labor:{" "}
                    {formatted(
                      Number(
                        jobDetails.data.sumPay.normalPay +
                          jobDetails.data.sumPay.overtimePay
                      )
                    )}
                  </span>
                </div>
              </div>

              <div className="space-y-3 pb-8 pt-10">
                <h1 className="text-2xl font-bold pb-4">Job Hours</h1>

                {jobDetails.data.filteredData.map((worker) => (
                  <div key={worker.id}>
                    <div
                      onClick={() => handleWorkerHoursDetails(worker.id)}
                      className="card flex items-center gap-4 bg-white card-shadow rounded-lg p-4"
                    >
                      <div
                        className="w-16 h-16 border border-gray-200  rounded-full"
                        style={{
                          backgroundImage: `url("${worker.workerImgUrl}"), url("${fallbackImage}")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                      <div className="grow">
                        <h2 className="text-lg font-medium tracking-wide">
                          {worker.workerName}
                        </h2>
                      </div>
                      <div className="text-4xl">
                        {workerHoursDetailsVisibility[worker.id] ? (
                          <ExpandMore
                            style={{ fontSize: "44", color: "#d8d8d8" }}
                          />
                        ) : (
                          <KeyboardArrowRight
                            style={{ fontSize: "44", color: "#d8d8d8" }}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={`card flex flex-col bg-white card-shadow rounded-lg p-4 overflow-x-auto ${
                        workerHoursDetailsVisibility[worker.id] ? "" : "hidden"
                      }`}
                    >
                      <table className="w-full min-w-max">
                        <thead>
                          <tr>
                            <th className="text-left">Date</th>
                            <th className="text-center">Hours</th>
                            <th className="text-center">Pay</th>
                          </tr>
                        </thead>
                        <tbody>
                          {worker.hours.map((hourlyDetail, index) => (
                            <tr key={index}>
                              <td className="border-t border-gray-200">
                                {moment(hourlyDetail.date).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td className="text-center border-t border-gray-200">
                                {hourlyDetail.normalHours +
                                  hourlyDetail.overtimeHours}
                              </td>
                              <td className="text-center border-t border-gray-200 font-medium text-red-500">
                                {formatted(
                                  hourlyDetail.normalPay +
                                    hourlyDetail.overtimePay
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
