import React, { useRef, useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  Search,
  Close,
  ArrowBackIos,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {
  useAddWorkers,
  useGetUserDetails,
  useGetUsers,
  useRemoveWorkers,
} from "../apiCalls/userApiCalls";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

export default function AddWorkers() {
  const workerIdsInputElement = useRef();
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { userId } = useParams();

  const { isLoading: isUserLoading, data: userDetails } = useGetUserDetails(
    userId,
    token
  );
  const { isLoading: isUsersLoading, data: workers } = useGetUsers(
    token,
    "",
    "worker",
    "name",
    999
  );
  const {
    mutate: addWorkersMutate,
    isLoading: isAddWorkersLoading,
    isError: isAddWorkersError,
    error: addWorkersError,
  } = useAddWorkers();
  const { mutate: removeWorkerMutate } = useRemoveWorkers();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      foremanId: userId,
      token: token,
      workerId: workerIdsInputElement.current?.value,
    };

    addWorkersMutate(data);
  };

  const handleRemove = (workerId) => {
    const data = {
      foremanId: userId,
      token: token,
      workerId: workerId,
    };

    removeWorkerMutate(data);
  };

  const fallbackImage = "/images/avatar.jpg";

  return (
    <>
      <div className="flex w-full items-center justify-center px-6">
        <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
          <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-teal-600 transition-colors">
            <button
              onClick={() => window.history.back()}
              className="w-full h-full rounded-full flex items-center justify-center"
            >
              <ArrowBackIos
                style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
              />
            </button>
          </div>
          <h1 className="text-2xl font-medium py-4">Foreman Details</h1>
          {isUserLoading ? (
            <Loader />
          ) : (
            <>
              <div className="relative mb-3 flex items-center justify-between px-4 z-10 btn-shadow py-4 rounded-xl">
                <div className="">
                  <p className="text-lg font-medium">
                    {userDetails.data.data.name}
                  </p>
                  <p className="text-base font-medium">
                    {userDetails.data.data.email}
                  </p>
                </div>
                <div
                  className="w-16 h-16 border border-gray-200  rounded-full absolute right-3"
                  style={{
                    backgroundImage: `url("${userDetails.data.data.imgUrl}"), url("${fallbackImage}")`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
            </>
          )}
          {isUsersLoading ? (
            <Loader />
          ) : (
            <>
              <div className="space-y-2 py-4">
                <label className="text-lg font-medium text-gray-900" htmlFor="">
                  Add Worker
                </label>
                <div className="flex gap-3">
                  <div className="rounded-xl w-full py-4 px-5 flex gap-2 border border-black">
                    <select
                      name="workerIds"
                      className="w-full outline-none"
                      ref={workerIdsInputElement}
                    >
                      <option value="">Select</option>
                      {workers.data.data.map((worker) => (
                        <>
                          <option key={worker._id} value={worker._id}>
                            {worker.name}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  <button
                    onClick={handleSubmit}
                    className="text-white w-1/3 text-center px-4 py-2 text-base font-medium tracking-wide hover:scale-105 transition-al bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-xl"
                  >
                    {isAddWorkersLoading ? "... Is Adding" : "Add"}
                  </button>
                  {isAddWorkersError && (
                    <div className="text-sm font-medium text-red-600 pt-2">
                      <p>{addWorkersError.response.data.error}</p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="mt-6 mb-10 space-y-4 md:flex md:justify-between w-full flex-wrap">
            {userDetails?.data.data.workers.map((worker) => (
              <div
                key={worker._id}
                className="card flex items-center justify-between bg-white card-shadow rounded-lg px-4 py-2 w-full "
              >
                <div className="flex gap-4 items-center py-1">
                  <div
                    className="w-16 h-16 border border-gray-200  rounded-full"
                    style={{
                      backgroundImage: `url("${worker.imgUrl}"), url("${fallbackImage}")`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div className="desc -space-y-1">
                    <h2 className="text-lg font-medium tracking-wide">
                      {worker.name}
                    </h2>
                  </div>
                </div>
                <div
                  onClick={() => handleRemove(worker._id)}
                  className="hover:scale-110 hover:transition-all hover:ease-in-out"
                >
                  <Close style={{ fontSize: 32 }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
