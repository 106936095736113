import React from "react";

const Tooltip = ({ text }) => {
  return (
    <div className="absolute w-max left-1/2 transform -translate-x-1/2 text-center text-xs bg-gray-300 text-gray-700 p-2 rounded-md shadow-md">
      {text}
    </div>
  );
};

export default Tooltip;
