import React, { useState } from "react";

export default function Settings() {
  return (
    <>
      <div className="flex flex-col justify-center mt-32 mx-6 md:mx-32 lg:mx-44 xl:mx-64">
        <div>
          <div className=" mb-8">
            <span className="font-bold text-xl">Platform Settings</span> (Coming
            Soon)
          </div>
          <div className="font-semibold">Time logging Method</div>
          <div className="flex gap-4">
            <div>
              <input
                className="cursor-pointer"
                type="radio"
                name="timeMethod"
                id="timeMethod1"
                checked="checked"
              />
              <label className="cursor-pointer" htmlFor="timeMethod1">
                {" "}
                Total Hours
              </label>
            </div>
            <div>
              <input
                className="cursor-pointer"
                type="radio"
                name="timeMethod"
                id="timeMethod2"
              />
              <label className="cursor-pointer" htmlFor="timeMethod2">
                {" "}
                Time In/Out
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
